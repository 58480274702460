import React from "react";
import { TList } from "../type";

interface Props {
  list: TList;
  deleteTask(task: string): void;
}

export default function List({ list, deleteTask }: Props) {
  return (
    <div style={{ display: "flex" }}>
      {list.task} - {list.day}{" "}
      <button onClick={() => deleteTask(list.task)}>x</button>
    </div>
  );
}
