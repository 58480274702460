import React, { FC, useState } from "react";
import "./App.css";
import { TList } from "./type";
import List from "./components/List";

const App: FC = () => {
  const [task, setTask] = useState<string>("");
  const [day, setDay] = useState<number>(0);
  const [list, setList] = useState<TList[]>([]);

  const addTask = (): void => {
    let newTask = { task: task, day: day };
    setList([...list, newTask]);
    setTask("");
    setDay(0);
  };

  const deleteTask = (task: string): void => {
    setList(
      list.filter((ls) => {
        return ls.task !== task;
      })
    );
  };

  return (
    <div className="App">
      <div className="header">
        <input
          type="text"
          placeholder="task"
          value={task}
          onChange={(e) => setTask(e.target.value)}
        />
        <input
          type="number"
          placeholder="day"
          value={day}
          onChange={(e) => setDay(parseInt(e.target.value))}
        />
        <button onClick={addTask}>ADD</button>
      </div>
      <div className="list" style={{ marginTop: "20px" }}>
        {list.map((list: TList, index: number) => {
          return <List key={index} list={list} deleteTask={deleteTask} />;
        })}
      </div>
    </div>
  );
};

export default App;
